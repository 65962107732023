import { CHAT } from '../actions/index';

export const connectToTwilio = client => {
  return {
    type: CHAT.CONNECT,
    load: client,
  };
};

export const getInbox = channels => dispatch => {
  dispatch({
    type: CHAT.FETCH_INBOX,
    load: channels,
  });
};

export const getCurrentChannel = channel => {
  return {
    type: CHAT.GET_CURRENT_CHANNEL,
    load: channel,
  };
};
export const getMessages = messages => {
  return {
    type: CHAT.FETCH_MESSAGES,
    load: messages,
  };
};

export const sendMessage = message => (dispatch, getState) => {
  console.log(getState().auth.userData.id);
  dispatch({
    type: CHAT.SEND_MESSAGE,
    load: message,
    userId: getState().auth.userData.id,
  });
};

export const toggleLoader = val => {
  return {
    type: CHAT.TOGGLE_LOADER,
    load: val,
  };
};

export const toggleInboxLoader = val => {
  return {
    type: CHAT.TOGGLE_INBOX_LOADER,
    load: val,
  };
};

export const setChatPool = list => dispatch => {
  dispatch({
    type: CHAT.SET_POOL,
    load: list,
  });
};
