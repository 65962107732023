import Page from 'components/Page';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import validator from 'validator';
import { TOAST } from '../../store/actions';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { useHistory } from 'react-router';
import {
  fetchSiteSettings,
  updateSiteSettings,
} from '../../store/requests/siteSettingRequest';
import style from './siteSettings.module.css';

const SiteSettingForm = () => {
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(false);

  const [siteSettings, setSiteSettings] = useState({
    freeDailySwipes: '',
    freeDirectMessages: '',
    pricePerDirectMessage: '',
    pricePerStandardMessage: '',
    pricePerPhotoMessage: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    fetchSiteSettings()
      .then(res => {
        console.log(res.data);
        setSiteSettings(res.data[0]);
      })
      .catch(err => {
        showError('Something went wrong');
      });
  }, []);

  const submitForm = async e => {
    setShowSpinner(true);
    e.preventDefault();
    let isValid = validation();
    let doRedirect = false;
    if (isValid) {
      const exactSiteSettings = {
        ...siteSettings,
        freeDailySwipes: Number(siteSettings.freeDailySwipes),
        freeDirectMessages: Number(siteSettings.freeDirectMessages),
        pricePerDirectMessage: Number(siteSettings.pricePerDirectMessage),
        pricePerStandardMessage: Number(siteSettings.pricePerStandardMessage),
        pricePerPhotoMessage: Number(siteSettings.pricePerPhotoMessage),
      };
      console.log('exact', exactSiteSettings);
      await updateSiteSettings(siteSettings.id, exactSiteSettings);
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'success',
          title: '',
          message: 'Site Settings updated',
          show: true,
        },
      });
    }

    setShowSpinner(false);
  };
  const showError = msg => {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: msg,
        show: true,
      },
    });
  };
  const validation = () => {
    if (
      !validator.isInt(String(siteSettings.freeDailySwipes)) &&
      siteSettings.freeDailySwipes < 0
    ) {
      showError('Invalid number of free daily swipes!');
      return false;
    } else if (
      !validator.isInt(String(siteSettings.freeDirectMessages)) &&
      siteSettings.freeDirectMessages < 0
    ) {
      showError('Invalid number of free direct messages!');
      return false;
    } else if (
      !validator.isInt(String(siteSettings.pricePerDirectMessage)) &&
      siteSettings.pricePerDirectMessage < 0
    ) {
      showError('Invalid price per direct message!');
      return false;
    } else if (
      !validator.isInt(String(siteSettings.pricePerStandardMessage)) &&
      siteSettings.pricePerStandardMessage < 0
    ) {
      showError('Invalid price per standard message!');
      return false;
    }
    return true;
  };

  return (
    <Page title="Site Settings">
      <Card>
        <CardHeader>Site Settings</CardHeader>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="swipes">Number of swipes</Label>
              <Input
                type="number"
                name="swipes"
                placeholder="Enter number of swipes"
                value={siteSettings.freeDailySwipes}
                onChange={e => {
                  e.persist();
                  setSiteSettings(prevState => ({
                    ...prevState,
                    freeDailySwipes: e.target.value,
                  }));
                }}
                min="0"
              />
            </FormGroup>
            <FormGroup>
              <Label for="msg-free-limit">
                Direct messages per day limit for free messages
              </Label>
              <Input
                type="number"
                name="display name"
                placeholder="Enter message per day limit for free messages"
                value={siteSettings.freeDirectMessages}
                onChange={e => {
                  e.persist();
                  setSiteSettings(prevState => ({
                    ...prevState,
                    freeDirectMessages: e.target.value,
                  }));
                }}
                min="0"
              />
            </FormGroup>
            <FormGroup>
              <Label for="msg-cost">Direct message cost per message</Label>
              <Input
                type="number"
                name="msg-cost"
                placeholder="Enter direct message cost per message"
                value={siteSettings.pricePerDirectMessage}
                onChange={e => {
                  e.persist();
                  setSiteSettings(prevState => ({
                    ...prevState,
                    pricePerDirectMessage: e.target.value,
                  }));
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="std-msg-cost">
                Standard message cost per message
              </Label>
              <Input
                type="number"
                name="std-msg-cost"
                placeholder="Enter standard message cost per message"
                value={siteSettings.pricePerStandardMessage}
                onChange={e => {
                  e.persist();
                  setSiteSettings(prevState => ({
                    ...prevState,
                    pricePerStandardMessage: e.target.value,
                  }));
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="gif-price">Price for sending picture</Label>
              <Input
                type="number"
                name="gif-price"
                placeholder="Enter price for sending gifs"
                value={siteSettings.pricePerPhotoMessage}
                onChange={e => {
                  e.persist();
                  setSiteSettings(prevState => ({
                    ...prevState,
                    pricePerPhotoMessage: e.target.value,
                  }));
                }}
              />
            </FormGroup>
            <Button
              disabled={showSpinner}
              onClick={submitForm}
              className={style.submitBtn}
            >
              {showSpinner ? (
                <Spinner animation="border" variant="info" size="sm" />
              ) : (
                'Submit'
              )}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Page>
  );
};

export default SiteSettingForm;
