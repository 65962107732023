import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React, { useState } from 'react';
import { FaGithub } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdList,
  MdExitToApp,
  MdWarning,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  ListGroup,
  ListGroupItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../store/actions/authAction';
import { useHistory } from 'react-router-dom';
import { exact } from 'prop-types';
const sidebarBackground = {
  // backgroundImage: `url("${sidebarBgImage}")`,
  backgroundColor: `#000`,

  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const getAccessibleMenuItems = role => {
  if (role === 'owner') {
    return [
      { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
      {
        to: '/animator-stats',
        name: 'Animator stats',
        exact: true,
        Icon: MdList,
      },
      {
        to: '/complaint',
        name: 'Complaints',
        exact: true,
        Icon: MdWarning,
      },

      { to: '/custom-gifs', name: 'Gifs', exact: true, Icon: MdWeb },
      { to: '/gifts', name: 'Gift', exact: true, Icon: MdWeb },
      { to: '/vibrators', name: 'Vibrators', exact: true, Icon: MdWeb },
      { to: '/asa', name: 'ASA', exact: true, Icon: MdWeb },

      { to: '/site-settings', name: 'Site Settings', exact: true, Icon: MdWeb },
      {
        to: '/additional-fields',
        name: 'Additional fields',
        exact: true,
        Icon: MdWeb,
      },
      {
        to: '/lucky-wheel',
        name: 'Lucky Wheel Slot',
        exact: true,
        Icon: MdWeb,
      },
    ];
  } else if (role === 'admin') {
    return [
      { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
      {
        to: '/animator-stats',
        name: 'Animator stats',
        exact: true,
        Icon: MdList,
      },
      {
        to: '/complaint',
        name: 'Complaints',
        exact: true,
        Icon: MdWarning,
      },
      { to: '/custom-gifs', name: 'Gifs', exact: true, Icon: MdWeb },
      { to: '/gifts', name: 'Gift', exact: true, Icon: MdWeb },
      { to: '/vibrators', name: 'Vibrators', exact: true, Icon: MdWeb },
    ];
  } else if (role === 'animator') {
    return [
      { to: '/dashboard', name: 'Dashboard', exact: true, Icon: MdDashboard },
      { to: '/chat', name: 'Chat', exact: true, Icon: MdDashboard },
    ];
  } else {
    return [];
  }
};

const getAccessableUserList = role => {
  if (role === 'owner') {
    return [
      { to: '/user/list/admin', name: 'Admin', exact: false, Icon: MdWeb },
      {
        to: '/user/list/animator',
        name: 'Animator',
        exact: false,
        Icon: MdWeb,
      },
      {
        to: '/user/list/user',
        name: 'User',
        exact: false,
        Icon: MdWeb,
      },
      {
        to: '/user/list/puppet',
        name: 'Puppet User',
        exact: false,
        Icon: MdWeb,
      },
    ];
  } else if (role === 'admin') {
    return [
      { to: '/user/list/admin', name: 'Admin', exact: false, Icon: MdWeb },
      {
        to: '/user/list/animator',
        name: 'Animator',
        exact: false,
        Icon: MdWeb,
      },
      {
        to: '/user/list/user',
        name: 'User',
        exact: false,
        Icon: MdWeb,
      },
      {
        to: '/user/list/puppet',
        name: 'Puppet User',
        exact: false,
        Icon: MdWeb,
      },
    ];
  } else if (role === 'animator') {
    return [
      {
        to: '/user/list/user',
        name: 'User',
        exact: false,
        Icon: MdWeb,
      },
      {
        to: '/user/list/puppet',
        name: 'Puppet User',
        exact: false,
        Icon: MdWeb,
      },
    ];
  }
  return [];
};

const bem = bn.create('sidebar');
const Sidebar = () => {
  const [state, setState] = useState({
    isOpenComponents: true,
    isOpenContents: false,
    isOpenPages: true,
  });

  const userData = useSelector(state => state.auth.userData);
  const navContents = getAccessableUserList(userData.role);
  const dispatch = useDispatch();
  const history = useHistory();
  const navItems = getAccessibleMenuItems(userData.role);
  const handleClick = name => () => {
    setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  return (
    <aside className={bem.b()}>
      <div className={bem.e('background')} style={sidebarBackground} />
      <div className={bem.e('content')}>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={logo200Image}
              width="40"
              height="30"
              className="pr-2"
              alt=""
            />
            <span className="text-white">iLoviou</span>
          </SourceLink>
        </Navbar>
        <Nav vertical>
          {navItems.map(({ to, name, exact, Icon }, index) => (
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className="text-capitalize"
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                <span className="">{name}</span>
              </BSNavLink>
            </NavItem>
          ))}

          {userData && userData.role !== 'animator' && (
            <>
              <NavItem
                className={bem.e('nav-item')}
                onClick={handleClick('Contents')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdSend className={bem.e('nav-item-icon')} />
                    <span className="">Users</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: state.isOpenContents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={state.isOpenContents}>
                {navContents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-capitalize"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
            </>
          )}

          <NavItem>
            <BSNavLink
              id={`navItem-signout}`}
              className="text-capitalize"
              // tag={NavLink}
              onClick={() => dispatch(logoutUser(userData.role))}
            >
              <MdExitToApp className="mr-1" /> Signout
            </BSNavLink>
          </NavItem>
        </Nav>
      </div>
    </aside>
  );
  // }
};

export default Sidebar;
