import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React, { useState, useRef } from 'react';
import { Card, Col, Row, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

import { useDispatch, useSelector } from 'react-redux';
import { userLoginAction } from '../store/actions/authAction';
import { TOAST } from '../store/actions';
// class AuthPage extends React.Component {
const AuthPage = ({ authState }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const initial_loading = useSelector(state => state.auth.initial_load);

  const handleLogoClick = () => {
    console.log('clicked');
    history.push('/dashboard');
  };

  const validateForm = () => {
    if (!validator.isEmail(email)) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Invalid email!',
          show: true,
        },
      });

      return false;
    } else if (!validator.isLength(password, { min: 3, max: 20 })) {
      dispatch({
        type: TOAST.SHOW,
        load: {
          type: 'error',
          title: 'Error',
          message: 'Invalid Password!',
          show: true,
        },
      });
      return false;
    } else {
      return true;
    }
  };

  const login = () => {
    let isValid = validateForm();
    if (isValid) {
      dispatch(userLoginAction({ email, password }, history));
    } else {
      return;
    }
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        {initial_loading ? (
          <Spinner
            size="lg"
            animation="border"
            className="page-spinner"
            variant="info"
          />
        ) : (
          <Card body>
            <AuthForm
              authState={authState}
              // onChangeAuthState={handleAuthState}
              onSubmit={login}
              onLogoClick={handleLogoClick}
              usernameInputProps={{
                value: email,
                type: 'email',
                placeholder: 'your@email.com',
                onChange: e => setEmail(e.target.value),
              }}
              passwordInputProps={{
                value: password,

                type: 'password',
                placeholder: 'your password',
                onChange: e => setPassword(e.target.value),
              }}
            />
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default AuthPage;
