import httpServices from '../../common/service';
import moment from 'moment';
const url = process.env.REACT_APP_BASE_URL;

export const fetchAnimatorLogs = (limit, offset, dates) => {
  console.log(dates.fromDate);
  let filter = {
    include: [
      {
        relation: 'user',
      },
    ],
    limit: limit,
    offset: offset,
    order: ['startTime DESC'],
  };
  if (dates && dates.fromDate !== '' && dates.toDate !== '') {
    console.log('here');
    let filterData = { ...filter };
    filterData.where = {
      endTime: {
        lte: moment(`${dates.toDate} 23:59:00`).format('YYYY-MM-DD HH:mm:ss'),
      },
      startTime: {
        gte: moment(`${dates.fromDate} 00:00:00`).format('YYYY-MM-DD HH:mm:ss'),
      },
    };
    filter = filterData;
  }

  try {
    let animatorLogs = httpServices.httpGetCall(
      `${url}/login-logs?filter=${encodeURI(JSON.stringify(filter))}`,
    );
    return animatorLogs;
  } catch (err) {
    return err;
  }
};

export const fetchLogCount = () => {
  try {
    let animatorLogsCount = httpServices.httpGetCall(`${url}/login-logs/count`);
    return animatorLogsCount;
  } catch (err) {
    return err;
  }
};

export const fetchChatPool = (limit, offset) => {
  let filter = {
    where: {
      isClaimed: false,
      needsAnimatorResponse: true,
    },
    order: ['lastUpdated DESC'],
    include: [
      {
        relation: 'user',
      },
      {
        relation: 'puppet',
      },
    ],
    limit: limit,
    offset: offset,
  };
  try {
    let chatPool = httpServices.httpGetCall(
      `${url}/chat-pools?filter=${encodeURI(JSON.stringify(filter))}`,
    );
    return chatPool;
  } catch (err) {
    return err;
  }
};

export const fetchAnimatorPool = animatorId => {
  let filter = {
    where: {
      isClaimed: true,
      animatorId: animatorId,
    },
    order: ['lastUpdated DESC'],
    include: [
      {
        relation: 'user',
      },
      {
        relation: 'puppet',
      },
    ],
  };
  try {
    let chatPool = httpServices.httpGetCall(
      `${url}/chat-pools?filter=${encodeURI(JSON.stringify(filter))}`,
    );
    return chatPool;
  } catch (err) {
    return err;
  }
};

export const fetchPoolCount = () => {
  try {
    let where = {
      isClaimed: false,
      needsAnimatorResponse: true,
    };
    let animatorLogsCount = httpServices.httpGetCall(
      `${url}/chat-pools/count?where=${encodeURI(JSON.stringify(where))}`,
    );
    return animatorLogsCount;
  } catch (err) {
    return err;
  }
};
