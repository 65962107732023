import httpServices from '../../common/service';
const url = process.env.REACT_APP_BASE_URL;

export const userLogin = async data => {
  let userLoginResp = await httpServices.httpPostCall(
    `${url}/users/login`,
    data,
  );
  //   let userLoginResp = httpServices.httpGetCall(`${url}/chat-rooms/count`);

  return userLoginResp;
};

export const getLogin = async () => {
  let userLoginResp = await httpServices.httpGetCall(`${url}/users/me`);
  //   let userLoginResp = httpServices.httpGetCall(`${url}/chat-rooms/count`);

  return userLoginResp;
};

export const fetchUserList = async (type, limit = 10, offset = 0) => {
  let uri = `${url}/users`;
  if (type) {
    let isPuppet = type === 'puppet' ? true : false;
    type =
      type === 'user' || type === 'puppet'
        ? { inq: ['user', 'premium-user'] }
        : type;

    let filter = {
      where: {
        role: type,
        status: { neq: 'deactivated' },
        isPuppet: isPuppet,
      },
      limit: limit,
      offset: offset,
    };
    uri += `/?filter=${JSON.stringify(filter)}`;
  }
  let userList = await httpServices.httpGetCall(`${uri}`);
  return userList;
};

export const fetchUserCount = async type => {
  let uri = `${url}/users/count`;
  if (type) {
    let isPuppet = type === 'puppet' ? true : false;
    type =
      type === 'user' || type === 'puppet'
        ? { inq: ['user', 'premium-user'] }
        : type;
    let filter = {
      role: type,
      status: { neq: 'deactivated' },
      isPuppet,
    };
    uri += `?where=${JSON.stringify(filter)}`;
  }
  let userCount = await httpServices.httpGetCall(`${uri}`);
  return userCount;
};

export const addUser = async data => {
  try {
    let userData = await httpServices.httpPostCall(`${url}/users`, data);
    return userData;
  } catch (err) {
    return err;
  }
};

export const setAvatar = async (id, data) => {
  try {
    let userData = await httpServices.httpPostCall(
      `${url}/users/${id}/set-avatar`,
      data,
    );
    return userData;
  } catch (err) {
    return err;
  }
};

export const fetchUserDetails = async id => {
  try {
    let userData = await httpServices.httpGetCall(`${url}/users/${id}`);
    return userData;
  } catch (err) {
    return err;
  }
};

export const updateUserDetails = async (id, data) => {
  try {
    let userData = await httpServices.httpPatchCall(`${url}/users/${id}`, data);
    return userData;
  } catch (err) {
    return err;
  }
};

export const deleteUser = async id => {
  try {
    let userData = await httpServices.httpDeleteCall(`${url}/users/${id}`);
    return userData;
  } catch (err) {
    return err;
  }
};

// set animator as active
export const updateAnimatorActive = async (messageCount = 0) => {
  try {
    let animatorData = await httpServices.httpPostCall(
      `${url}/users/animator/is-active?messagesSent=${messageCount}`,
      {},
    );
    return animatorData;
  } catch (err) {
    return err;
  }
};

/**
 * Get twilio token for chat
 */
export const getToken = async () => {
  const uri = `${url}/users/get-chat-token`;
  const response = await httpServices.httpGetCall(uri);
  return response;
};

/**
 * Logout a user from server
 */

export const logoutUserReq = async () => {
  const uri = `${url}/users/animator/release-all-channels`;
  const response = await httpServices.httpPostCall(uri);
  return response;
};

export const uploadPicture = async (id, query, data) => {
  try {
    const post = `name=${query.name}&type=${query.type}&isLocked=${query.isLocked}&unlockPrice=${query.unlockPrice}`;
    let userData = await httpServices.httpPostCall(
      `${url}/puppets/upload-photo/${id}?${post}`,
      data,
    );
    return userData;
  } catch (err) {
    return err;
  }
};

export const fetchPrivatePhotos = async id => {
  try {
    let userData = await httpServices.httpGetCall(
      `${url}/users/${id}/user-photos`,
    );
    return userData;
  } catch (err) {
    return err;
  }
};

export const deletePhotoById = async id => {
  try {
    let userData = await httpServices.httpDeleteCall(
      `${url}/user-photos/${id}`,
    );
    return userData;
  } catch (err) {
    return err;
  }
};
