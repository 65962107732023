export const AUTH = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  INITIAL_LOAD: 'INITIAL_LOAD',
};

export const TOAST = {
  SHOW: 'SHOW',
};

export const CHAT = {
  CONNECT: 'CONNECT',
  FETCH_INBOX: 'FETCH_INBOX',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  GET_CURRENT_CHANNEL: 'GET_CURRENT_CHANNEL',
  SEND_MESSAGE: 'SEND_MESSAGE',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  TOGGLE_INBOX_LOADER: 'TOGGLE_INBOX_LOADER',
  SET_POOL: 'SET_POOL',
  SET_COUNT: 'SET_COUNT',
};
