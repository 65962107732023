import { Client as TwilioChatClient } from 'twilio-chat';
import store from '../../store/reducers/index';
import {
  connectToTwilio,
  sendMessage,
  getInbox,
  getCurrentChannel,
} from '../../store/actions/chatAction';
import { getToken } from '../../store/requests/userRequest';
import { fetchAnimatorPool } from '../../store/requests/dashboardRequest';

let client;

export const chatInit = async () => {
  const response = await getToken();
  const token = response.data.token;
  TwilioChatClient.create(token, {})
    .then(chatClient => {
      client = chatClient;
      store.dispatch(connectToTwilio(chatClient));
      fetchInbox();
      subscribeToAllChatClientEvents();
    })
    .catch(e => null);
};

/**
 * Create a chat channel.
 * 1:1 chat is a private channel in Twilio
 * @param {string} uniqueName
 * @param {string} friendlyName
 * @param {string} toId
 */
export const createChannel = (uniqueName, friendlyName, toId) => {
  return new Promise((resolve, reject) => {
    const chatClient = store.getState().chat.client;
    chatClient
      .createChannel({
        uniqueName: uniqueName,
        friendlyName: friendlyName,
        isPrivate: true,
      })
      .then(function (channel) {
        joinChannel(channel);
        inviteUser(channel, toId);
        resolve(channel);
      })
      .catch(e => reject(false));
  });
};

export const joinChannel = channel => {
  channel
    .join()
    .then(() => {})
    .catch(function (err) {
      console.log('Error in joining channel', err);
    });
};

/**
 * Invite other user to chat
 * @param {any} channel
 * @param {string} userId
 */
export const inviteUser = (channel, userId) => {
  channel
    .invite(userId)
    .then(function () {})
    .catch(e => console.log(e));
};

/**
 * @param {any} channel
 * Fetch messages for a specific channel
 */
export const fetchMessages = (channel, currentUserId) => {
  return new Promise((resolve, reject) => {
    channel
      .getMessages()
      .then(function (messages) {
        const items = [...messages.items];
        let messageList = [];
        items.forEach(val => {
          messageList.push({
            id: val.state.sid,
            message: val.state.body,
            self: val.state.author === currentUserId ? true : false,
            time: val.state.timestamp,
            media: val.state.media ? val.state.media.state.filename : null,
          });
        });
        resolve(messageList);
      })
      .catch(e => {
        console.log(e);
        reject([]);
        //   store.dispatch(toggleLoader(false));
      });
  });
};

export const fetchInbox = () => {
  const chatClient = store.getState().chat.client;
  if (!chatClient) {
    return;
  }
  chatClient
    .getSubscribedChannels()
    .then(function (paginator) {
      const sortedList = sortInboxByTime(paginator.items);
      const animator = store.getState().auth.userData;
      fetchAnimatorPool(animator.id).then(response => {
        const list = response.data;
        for (let i = 0; i < sortedList.length; i++) {
          const channelState = sortedList[i].channelState;
          for (let j = 0; j < list.length; j++) {
            if (
              channelState.uniqueName.includes(list[j].puppetId) &&
              channelState.uniqueName.includes(list[j].userId)
            ) {
              sortedList[i].channelState.puppet = list[j].puppet;
              sortedList[i].channelState.appUser = list[j].user;
              sortedList[i].channelState.chatPoolId = list[j].id;
              sortedList[i].channelState.needsAnimatorResponse =
                list[j].needsAnimatorResponse;
            }
          }
        }
        store.dispatch(getInbox(sortedList));
      });
    })
    .catch(e => {});
};

const sortInboxByTime = channels => {
  let sortedList = [...channels];
  sortedList.sort((a, b) => {
    return b.lastMessage.timestamp - a.lastMessage.timestamp;
  });
  return sortedList;
};

/**
 * @param {any} channel
 * @param {string} text
 */
export const sendTextMessage = (channel, text) => {
  channel.sendMessage(text);
};

const subscribeToAllChatClientEvents = () => {
  client.on('tokenAboutToExpire', obj =>
    console.log('ChatClientHelper.client', 'tokenAboutToExpire', obj),
  );

  client.on('channelInvited', channel => {
    channel.join();
  });

  client.on('messageAdded', obj => {
    const currentChannel = store.getState().chat.currentChannel;
    const animatorId = store.getState().auth.userData.id;
    const isSentByAppUser = obj.state.author !== animatorId;
    const messageType = getMessageType(obj.state.body);
    if (currentChannel) {
      if (currentChannel.sid === obj.channel.sid && isSentByAppUser) {
        store.dispatch(sendMessage(obj));
      } else {
        if (messageType === 'text' || messageType === 'gif') {
          showUnreadMessage(obj.channel.sid, isSentByAppUser);
        } else {
          store.dispatch(sendMessage(obj));
        }
      }
    } else {
      if (messageType === 'text' || messageType === 'gif') {
        showUnreadMessage(obj.channel.sid, isSentByAppUser);
      } else {
        store.dispatch(sendMessage(obj));
      }
    }
  });

  const getMessageType = text => {
    if (text.includes('link=') && text.includes('uploads/gift-images')) {
      return 'gift';
    } else if (text.includes('gif_sent_link=')) {
      return 'gif';
    } else if (
      text.includes('chat-image=') &&
      text.includes('uploads/chat-images')
    ) {
      return 'image';
    }
    return 'text';
  };
  const showUnreadMessage = (sid, isSentByAppUser) => {
    let channels = [...store.getState().chat.inbox];
    const index = channels.findIndex(channel => channel.sid === sid);
    channels[index].channelState.needsAnimatorResponse = isSentByAppUser;
    store.dispatch(getInbox(channels));
    if (!isSentByAppUser) {
      store.dispatch(getCurrentChannel(null));
    }
  };

  client.on('userUpdated', obj =>
    console.log('ChatClientHelper.client', 'userUpdated', obj),
  );
};
