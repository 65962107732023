import { TOAST } from '../store/actions';
import store from '../store/reducers';

const axios = require('axios');

// import { showLoading, hideLoading, showErrorToast } from "../lib/loader";
// import { getData } from "./asyncStorage";
const services = {};
axios.interceptors.request.use(
  async config => {
    // Do something before request is sent
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    let token = localStorage.getItem('auth-token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    store.dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: 'Oops! Something went wrong',
        show: true,
      },
    });
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response;
  },
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  error => {
    return Promise.reject(error);
  },
);

services.axiosCall = option => {
  return new Promise((resolve, reject) => {
    axios(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        console.log(err);
        handleError(err);
        reject(err);
      });
  });
};

services.httpGetCall = url =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'get',
      url: url,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

services.httpPostCall = (url, data) => {
  // console.log(url, data);

  return new Promise((resolve, reject) => {
    const option = {
      method: 'post',
      url: url,
      data,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};
services.httpPutCall = (url, data) =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'put',
      url: url,
      data,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

services.httpPatchCall = (url, data) =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'patch',
      url: url,
      data,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

services.httpDeleteCall = url =>
  new Promise((resolve, reject) => {
    const option = {
      method: 'delete',
      url: url,
    };
    services
      .axiosCall(option)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        reject(err);
      });
  });

const handleError = err => {
  if (
    err &&
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error.message
  ) {
    // showErrorToast(err.response.data.message);
    store.dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: err.response.data.error.message,
        show: true,
      },
    });
  } else {
    store.dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: 'Oops! Something went wrong',
        show: true,
      },
    });
    // showErrorToast("Oops! Something went wrong");
  }
};

export default services;
