import {
  userLogin,
  getLogin,
  updateAnimatorActive,
  logoutUserReq,
} from '../requests/userRequest';
import { AUTH, TOAST, CHAT } from './index';
import { chatInit } from '../../pages/chat/chat-helper';
import store from '../reducers';
export const userLoginAction = (data, history) => async dispatch => {
  let userLoginResp = await userLogin(data);
  let user = userLoginResp.data.data.user;
  let token = userLoginResp.data.data.token;
  // console.log(token, userLoginResp.data);
  if (['admin', 'owner', 'animator'].indexOf(user.role) > -1) {
    if (user.role === 'animator') {
      updateAnimatorActive();
      chatInit();
      dispatch({ type: CHAT.SET_COUNT, load: 0 });
      var event = new CustomEvent('animatorIsActive');
      window.dispatchEvent(event);
    }
    localStorage.setItem('auth-token', token);
    localStorage.setItem('role', user.role);
    dispatch({ type: AUTH.LOGIN, load: user });
    return history.push('/dashboard');
  } else {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: "You don't have access please contact to owner !",
        show: true,
      },
    });
  }
};

export const checkLogin = () => async dispatch => {
  let userLoginResp = await getLogin();
  dispatch({ type: AUTH.INITIAL_LOAD, load: true });

  if (!userLoginResp || userLoginResp.error) {
    dispatch({
      type: TOAST.SHOW,
      load: {
        type: 'error',
        title: 'Error',
        message: 'You have no login, Please login again!',
        show: true,
      },
    });
    window.location.href = '/';
  } else {
    let user = userLoginResp.data;
    if (user.role === 'animator') {
      updateAnimatorActive();
      chatInit();

      var event = new CustomEvent('animatorIsActive');
      window.dispatchEvent(event);
    }
    dispatch({ type: AUTH.LOGIN, load: user });
    if (window.location.pathname === '/') {
      window.location.href = '/dashboard';
    }
  }
};

export const logoutUser = role => async dispatch => {
  if (role === 'animator') {
    let state = store.getState();

    updateAnimatorActive(state.chat.messageCount);
    let logoutResp = await logoutUserReq();
  }
  // if (logoutResp && logoutResp.msg) {
  window.removeEventListener('animatorIsActive', () => {});
  dispatch({ type: 'LOGOUT' });
  localStorage.clear();
  window.location.href = '/';
  // }
};
