import { AUTH } from '../actions';

const initialState = {
  userData: {},
  initial_load: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH.LOGIN:
      return { ...state, userData: action.load };
    case AUTH.INITIAL_LOAD:
      return { ...state, initial_load: action.load };
    default:
      return state;
  }
};
