import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import { Provider } from 'react-redux';
import store from './store/reducers';
import Toast from 'components/Toast';
import { updateAnimatorActive } from './store/requests/userRequest';
import { checkLogin } from './store/actions/authAction';
import { CHAT } from './store/actions';

import SiteSettingForm from './pages/siteSettings/siteSettingsForm';

const LuckyWheelForm = React.lazy(() =>
  import('./pages/luckyWheelSlot/luckyWheelForm'),
);
const LuckyWheelList = React.lazy(() =>
  import('./pages/luckyWheelSlot/luckyWheelList'),
);
const VibratorForm = React.lazy(() => import('./pages/vibrators/vibratorForm'));
const VibratorList = React.lazy(() => import('./pages/vibrators/vibratorList'));
const GiftGifForm = React.lazy(() => import('./pages/giftGif/giftGifForm'));
const GiftGifList = React.lazy(() => import('./pages/giftGif/gifsGiftsList'));
const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashBoard/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
// const AdminForm = React.lazy(() => import('pages/AdminForm'));
const UserList = React.lazy(() => import('pages/users/UserList'));
const ChatPage = React.lazy(() => import('./pages/chat/ChatPage'));
// const UserForm = React.lazy(() => import('pages/UserForm'));
const AddEditUser = React.lazy(() => import('pages/users/AddEditUser.js'));
const AsaList = React.lazy(() => import('pages/asa/List'));
const AddEditAsa = React.lazy(() => import('pages/asa/AddEditAsa'));
const AnimatorStats = React.lazy(() =>
  import('pages/AnimatorStats/AnimatorStats'),
);
const Complaint = React.lazy(() => import('pages/Complaint/Complaint'));
const AdditionalFields = React.lazy(() => import('pages/AditionalFields'));
const AddEditAdditionalFields = React.lazy(() =>
  import('pages/AditionalFields/AddEdit'),
);
const getBasename = () => {
  return `iloviou`;
};

class App extends React.Component {
  componentWillMount() {
    if (localStorage.getItem('auth-token')) {
      store.dispatch(checkLogin());
      window.addEventListener('animatorIsActive', () => {
        setInterval(() => {
          let state = store.getState();

          updateAnimatorActive(state.chat.messageCount);
        }, 300000);
      });
      let messageCount = localStorage.getItem('messageCount');
      messageCount = messageCount ? parseInt(messageCount) : 0;
      store.dispatch({ type: CHAT.SET_COUNT, load: messageCount });
      localStorage.removeItem('messageCount');

      window.addEventListener('beforeunload', this.onUnload);
    } else {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = () => {
    let state = store.getState();
    localStorage.setItem('messageCount', state.chat.messageCount);
  };
  render() {
    return (
      <Provider store={store}>
        <Toast />
        <BrowserRouter
        // basename={getBasename()}
        >
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path="/"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <LayoutRoute
                exact
                path="/signup"
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_SIGNUP} />
                )}
              />

              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Route exact path="/dashboard" component={DashboardPage} />
                  <Route exact path="/login-modal" component={AuthModalPage} />
                  <Route exact path="/buttons" component={ButtonPage} />
                  <Route exact path="/cards" component={CardPage} />
                  <Route exact path="/widgets" component={WidgetPage} />
                  <Route exact path="/typography" component={TypographyPage} />
                  <Route exact path="/alerts" component={AlertPage} />
                  <Route exact path="/tables" component={TablePage} />
                  <Route exact path="/badges" component={BadgePage} />
                  <Route exact path="/asa" component={AsaList} />
                  <Route exact path="/asa/add" component={AddEditAsa} />
                  <Route exact path="/asa/edit/:id" component={AddEditAsa} />
                  <Route exact path="/complaint" component={Complaint} />
                  <Route
                    exact
                    path="/additional-fields"
                    component={AdditionalFields}
                  />
                  <Route
                    exact
                    path="/add-additional-fields"
                    component={AddEditAdditionalFields}
                  />
                  <Route
                    exact
                    path="/edit-additional-fields/:index"
                    component={AddEditAdditionalFields}
                  />

                  <Route
                    exact
                    path="/animator-stats"
                    component={AnimatorStats}
                  />
                  <Route
                    exact
                    path="/button-groups"
                    component={ButtonGroupPage}
                  />
                  <Route exact path="/dropdowns" component={DropdownPage} />
                  <Route exact path="/progress" component={ProgressPage} />
                  <Route exact path="/modals" component={ModalPage} />
                  <Route exact path="/forms" component={FormPage} />
                  <Route
                    exact
                    path="/input-groups"
                    component={InputGroupPage}
                  />
                  <Route exact path="/charts" component={ChartPage} />
                  <Route exact path="/vibrators/" component={VibratorList} />
                  <Route
                    exact
                    path="/vibrator/edit/:index"
                    component={VibratorForm}
                  />
                  <Route exact path="/custom-gifs/" component={GiftGifList} />
                  <Route exact path="/gifts/" component={GiftGifList} />
                  <Route
                    exact
                    path="/custom-gifs/add"
                    component={GiftGifForm}
                  />
                  <Route exact path="/gifts/add" component={GiftGifForm} />
                  <Route
                    exact
                    path="/custom-gifs/edit/:id"
                    component={GiftGifForm}
                  />
                  <Route
                    exact
                    path="/site-settings"
                    component={SiteSettingForm}
                  />
                  <Route
                    exact
                    path="/lucky-wheel/edit/:id"
                    component={LuckyWheelForm}
                  />
                  <Route exact path="/lucky-wheel" component={LuckyWheelList} />
                  <Route exact path="/gifts/edit/:id" component={GiftGifForm} />
                  {/* <Route exact path="/user/list/:type" component={UserForm} /> */}
                  {/* <Route exact path="/list/:role" component={UserForm} /> */}
                  <Route exact path="/user/add/:type" component={AddEditUser} />
                  <Route path="/user/edit/:userId" component={AddEditUser} />
                  <Route path="/user/list/:type" component={UserList} />
                  <Route exact path="/chat" component={ChatPage} />
                  <Route exact path="/chat/:sid" component={ChatPage} />
                </React.Suspense>
              </MainLayout>
              <Redirect to="/" />
            </Switch>
          </GAListener>
        </BrowserRouter>
      </Provider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
