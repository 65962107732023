import { CHAT } from '../actions/index';
import moment from 'moment';

const initialState = {
  client: null,
  inbox: [],
  currentChannel: null,
  messages: [],
  showLoader: false,
  showInboxLoader: false,
  pool: [],
  messageCount: 0,
  currentPuppet: null,
  currentUser: null,
};
const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT.CONNECT:
      return {
        ...state,
        client: action.load,
      };
    case CHAT.FETCH_INBOX:
      return {
        ...state,
        inbox: action.load,
      };
    case CHAT.GET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: action.load,
        currentPuppet: action.load ? action.load.channelState.puppet : null,
        currentUser: action.load ? action.load.channelState.appUser : null,
      };
    case CHAT.FETCH_MESSAGES: {
      return {
        ...state,
        messages: action.load,
      };
    }
    case CHAT.TOGGLE_LOADER:
      return {
        ...state,
        showLoader: action.load,
      };
    case CHAT.TOGGLE_INBOX_LOADER:
      return {
        ...state,
        showInboxLoader: action.load,
      };
    case CHAT.SET_POOL:
      return {
        ...state,
        pool: action.load,
      };
    case CHAT.SEND_MESSAGE:
      const messageList = [...state.messages];
      if (
        messageList.findIndex(item => item.id === action.load.state.index) ===
        -1
      ) {
        messageList.push({
          _id: action.load.state.sid,
          message: action.load.state.body,
          self: action.load.state.author === action.userId,
          time: action.load.state.timestamp,
          media: action.load.state.media
            ? action.load.state.media.state.filename
            : null,
        });
      }
      return {
        ...state,
        messages: messageList,
      };
    case CHAT.SET_COUNT:
      return { ...state, messageCount: action.load };
    default:
      return state;
  }
};

export default chatReducer;
