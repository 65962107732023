import httpServices from '../../common/service';
const url = process.env.REACT_APP_BASE_URL;

export const fetchSiteSettings = async (type, limit = 10, offset = 0) => {
  let uri = `${url}/site-settings`;
  let vibratorList = await httpServices.httpGetCall(`${uri}`);
  return vibratorList;
};

export const updateSiteSettings = async (id, data) => {
  try {
    let userData = await httpServices.httpPatchCall(
      `${url}/site-settings/${id}`,
      data,
    );
    return userData;
  } catch (err) {
    return JSON.parse(JSON.stringify(err));
  }
};
